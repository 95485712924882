<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("payments") }}</h2>
      <p>{{ $t("payments-.sub-heading") }}</p>
    </heading>
    <filtered-table :baseFilters="filters" />
  </v-container>
</template>

<script>
import Heading from "../components/Heading";
import FilteredTable from "./FilteredTable";

export default {
  mixins: [],
  components: {
    Heading,
    FilteredTable,
  },
  data: () => ({
    filters: { status: ["pending"] },
  }),
  computed: {},
  watch: {},
  created: async function () {
    if (this.$route.query.status) {
      this.filters.status = [this.$route.query.status];
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
